import * as React from 'react'
import * as styles from "./projectbox.module.css"

const ProjectBox = ({ title, children }) => {
    return (
        <div className={styles.projectbox}>
            <div className={styles.projectboxinner}>
                <div className={styles.projectboxcontent}>
                    {children}
                    <p>{title}</p>
                </div>
            </div>
        </div>
    )
}

export default ProjectBox