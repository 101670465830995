import * as React from "react"
import HomeLayout from "../components/homelayout"
import HomeHero from "../components/homehero"
import HomeProjects from "../components/homeprojects"
import HomeContact from "../components/homecontact"
import HomeFooter from "../components/homefooter"
import SEO from "../components/seo"


const IndexPage = () => {
  return (
    <SEO pageTitle="APPERCASED" pageDescription="We’re delivering digital tools which helps you achieve your goals both in business and personal life.">
      <HomeLayout>
        <HomeHero />
        <HomeProjects />
        <HomeContact />
        <HomeFooter />
      </HomeLayout>
    </SEO>
  )
}

export default IndexPage
