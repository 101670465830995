import * as React from 'react'
import * as styles from "./homefooter.module.css"
import ContactInfo from './contactinfo'
import RectangleLinkButton from './rectangle_link_button'

const HomeFooter = () => {
    return (
        <div className={styles.homefooter}>
            <div className={styles.homefootercontent}>
                <ContactInfo />
                <div className={styles.contactformbox}>
                    <div className={styles.contactform}>
                        <h2>Any questions? Let's talk!</h2>
                        <RectangleLinkButton link="mailto: hello@appercased.com">
                            <p>Contact us</p>
                        </RectangleLinkButton>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HomeFooter