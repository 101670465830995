import * as React from 'react'
import * as styles from "./homecontact.module.css"
import { StaticImage } from 'gatsby-plugin-image'

const HomeContact = () => {
    return (
        <div id="about" className={styles.contact}>
            <div className={styles.contactcolumns}>
                <div className={styles.portraitbox}>
                    <div className={styles.portrait}>
                        <StaticImage src="../images/portrait.png" objectFit="contain" alt="Portrait photo of Krzysztof Rózga, APPERCASED Founder & Software Engineer" />
                    </div>
                </div>
                <div className={styles.contactcontent}>
                    <h2>Our mission</h2>
                    <p className={styles.quote}>We’re delivering tools which helps you achieve your goals both in business and personal life. We’re passionate about creating high quality and performant software which combined with unique design skill set results in best possible mobile user experience.</p>
                    <div className={styles.signature}>
                        <p>Krzysztof Rózga</p>
                        <span className={styles.jobtitle}><p>APPERCASED Founder & Software Engineer</p></span>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default HomeContact