import * as React from 'react'
import * as styles from "./homeprojects.module.css"
import ProjectPreview from './projectpreview'
import { StaticImage } from 'gatsby-plugin-image'

const HomeProjects = () => {
    return (
        <div id="projects" className={styles.previewssection}>
            <div className={styles.previews}>
                <div className={styles.leftcol}>
                    <div id="sdk">
                        <ProjectPreview title="WatchNews SDK" link="/sdk/watchnews" description="WatchNews SDK is a tool dedicated to news apps owners which allows to easily embed fully customisable Apple Watch® app within existing iOS® application.">
                            <StaticImage src="../images/screen_watchnews.png" objectFit="contain" alt="APPERCASED icon" />
                        </ProjectPreview>
                    </div>
                    <div id="snapcards">
                        <ProjectPreview title="Snapcards App" link="https://apps.apple.com/us/app/snapcards-learn-vocabulary/id1555335044" description="Snapcards app simplifies learning foreign languages by converting vocabulary lists with Camera Scan tool into engaging and effective educational mobile games.">
                            <StaticImage src="../images/screen_snapcards.png" objectFit="contain" alt="Snapcards icon" />
                        </ProjectPreview>
                    </div>
                </div>
                <div className={styles.rightcol}>
                    <div id="watchplus">
                        <ProjectPreview title="Watch+ App" link="https://watchplus.app/" description="Watch+ app allows users to customize their Apple Watch® with useful widgets which enhance Watch functionality and offers eye-catching wallpaper gallery.">
                            <StaticImage src="../images/screen_watchplus.png" objectFit="contain" alt="Watch+ icon" />
                        </ProjectPreview>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HomeProjects