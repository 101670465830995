import * as React from 'react'
import * as styles from "./homehero.module.css"
import type { Container, Engine } from "tsparticles-engine";
import { loadFull } from "tsparticles";
import Particles from "react-particles";
import { StaticImage } from 'gatsby-plugin-image'
import { AnchorLink } from "gatsby-plugin-anchor-links";
import Content from './content'
import ProjectBox from './projectbox';

const HomeHero = () => {
    const particlesInit = React.useCallback(async (engine: Engine) => {
        console.log(engine);

        // you can initialize the tsParticles instance (engine) here, adding custom shapes or presets
        // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
        // starting from v2 you can add only the features you need reducing the bundle size
        await loadFull(engine);
    }, []);

    const particlesLoaded = React.useCallback(async (container: Container | undefined) => {
        await console.log(container);
    }, []);

    return (
        <div className={styles.homehero}>

            <Particles
                id="tsparticles"
                className={styles.particles}
                init={particlesInit}
                loaded={particlesLoaded}
                options={{
                    fullScreen: {
                        enable: false,
                        zIndex: 100
                    },
                    // background: {
                    //     color: {
                    //         value: "#0d47a1",
                    //     },
                    // },
                    fpsLimit: 120,
                    interactivity: {
                        detectsOn: "window",
                        events: {
                            onClick: {
                                enable: true,
                                mode: "push",
                            },
                            onHover: {
                                enable: true,
                                mode: "repulse",
                            },
                            resize: true,
                        },
                        modes: {
                            push: {
                                quantity: 4,
                            },
                            repulse: {
                                distance: 200,
                                duration: 0.4,
                            },
                        },
                    },
                    particles: {
                        color: {
                            value: "#404040",
                        },
                        links: {
                            color: "#808080",
                            distance: 150,
                            enable: true,
                            opacity: 0.5,
                            width: 1,
                        },
                        collisions: {
                            enable: true,
                        },
                        move: {
                            direction: "none",
                            enable: true,
                            outModes: {
                                default: "bounce",
                            },
                            random: false,
                            speed: 0.5,
                            straight: false,
                        },
                        number: {
                            density: {
                                enable: true,
                                area: 1000,
                            },
                            value: 80,
                        },
                        opacity: {
                            value: 0.5,
                        },
                        shape: {
                            type: "circle",
                        },
                        size: {
                            value: { min: 2, max: 4 },
                        },
                    },
                    detectRetina: true,
                }}
            />
            <nav>
                <ul>
                    <li><AnchorLink to="/#projects"><a>Projects</a></AnchorLink></li>
                    <li><p>/</p></li>
                    <li><AnchorLink to="/#about"><a>About</a></AnchorLink></li>
                    <li><p>/</p></li>
                    <li><a href="mailto: hello@appercased.com">Contact</a></li>
                </ul>
            </nav>
            <div className={styles.header}>
                <StaticImage src="../images/banner.png" objectFit="contain" height={50} alt="APPERCASED logo" />
                <h1>Digital goods.<br />For everyday use.</h1>
            </div>
            <div className={styles.products}>
                <div className={styles.projects}>
                    <AnchorLink to="/#sdk">
                        <ProjectBox title="WatchNews">
                            <StaticImage src="../images/sdk_logo_white.png" objectFit="contain" height={35} alt="APPERCASED logo" />
                        </ProjectBox>
                    </AnchorLink>
                    <AnchorLink to="/#watchplus">
                        <ProjectBox title="Watch+">
                            <StaticImage src="../images/watchplus_logo_white.png" objectFit="contain" height={35} alt="Watch+ app icon" />
                        </ProjectBox>
                    </AnchorLink>
                    <AnchorLink to="/#snapcards">
                        <ProjectBox title="Snapcards">
                            <StaticImage src="../images/snapcards_logo_white.png" objectFit="contain" height={35} alt="Snapcards app icon" />
                        </ProjectBox>
                    </AnchorLink>
                </div>
            </div>

        </div>

    )
}

export default HomeHero