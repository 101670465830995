import * as React from 'react'
import * as styles from "./projectpreview.module.css"
import RectangleLinkButton from './rectangle_link_button'

const ProjectPreview = ({ title, description, link, children }) => {
    return (
        <div className={styles.preview}>
            <div className={styles.screenshot}>
                {children}
            </div>
            <div className={styles.description}>
                <h2>{title}</h2>
                <hr></hr>
                <p>{description}</p>
                <a href={link}>Learn more</a>
            </div>

        </div>
    )
}

export default ProjectPreview