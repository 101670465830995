import * as React from 'react'
import * as styles from "./rectangle_link_button.module.css"

const RectangleLinkButton = ({ link, children }) => {
    return (
        <div className={styles.rectanglebutton}>
            <a href={link}>{children}</a>
        </div>
    )
}

export default RectangleLinkButton