import * as React from 'react'
import * as styles from "./contactinfo.module.css"
import { StaticImage } from 'gatsby-plugin-image'

const ContactInfo = () => {
    return (
        <div className={styles.footercolumn}>
            <StaticImage src="../images/banner.png" objectFit="contain" height={35} alt="APPERCASED logo" />
            <p className={styles.highlight}>Krzysztof Rózga Mobile Apps</p>
            <p><span className={styles.highlight}>E-mail:</span> <a href="mailto: hello@appercased.com">hello@appercased.com</a></p>
            <p><span className={styles.highlight}>Address:</span> Komornicka 31/2,</p>
            <p>62-069 Dąbrówka, Poland</p>
            <p><span className={styles.highlight}>EU VAT id:</span> PL7792503185</p>
        </div>
    )
}

export default ContactInfo